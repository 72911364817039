import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  Alert,
} from "@mui/material";
import { postData } from "../../services/Request";
import CircularProgress from "@mui/material/CircularProgress";

export default function AdminLogIn() {
  const nav = useNavigate();

  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [loader, setLoder] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    var details = {
      email: e.target.email.value,
      password: e.target.password.value,
    };
    if (details.email.trim() === "") {
      setAlertMessage("Input your email");
      setSeverity("warning");
    } else if (details.password.trim() === "") {
      setAlertMessage("Enter your Passwords");
      setSeverity("warning");
    } else {
      setLoder(true);
      setAlertMessage("");

      setLoder(false);
      if (details.email === "Admin" && details.password === "Ab$123") {
        localStorage.setItem("user", JSON.stringify({ usertype: "Admin" }));
        setAlertMessage("Logging you in......");
        setSeverity("success");
        nav("/usersTable");
      } else {
        setAlertMessage("Enter Correct User and Password");
        setSeverity("error");
      }
    }
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        {alertMessage && (
          <Alert variant="filled" severity={severity}>
            {alertMessage}
          </Alert>
        )}
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: "32px", fontWeight: "700" }}>
            Welcome To Ajoo
          </Typography>

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />

            {loader === false && (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Log In
              </Button>
            )}
            {loader === true && (
              <Button fullWidth variant="outlined">
                <CircularProgress />
              </Button>
            )}
          </Box>
        </Box>
      </Container>
    </>
  );
}
