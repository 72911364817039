import React from "react";
import { Route, Routes } from "react-router-dom";
import { LoaderProvider } from "./context/LoaderContext";
import AdminLogIn from "./pages/admin/AdminLogin";
import UserTable from "./pages/admin/UsersTable";
import InstructorTable from "./pages/admin/InstructorTable";

import Loader from "./components/Loader";

function App() {
  return (
    <LoaderProvider>
      <div className="App">
        <Loader />
        <Routes>
          <Route path="/" element={<AdminLogIn />} />

          {/* Admin  */}
          <Route path="/admin" element={<AdminLogIn />} />
          <Route path="/usersTable" element={<UserTable />} />
          <Route path="/InstructorTable" element={<InstructorTable />} />
        </Routes>
      </div>
    </LoaderProvider>
  );
}

export default App;
