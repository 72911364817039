import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
// import Logo from "../../assets/Header/logo.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, Grid } from "@mui/material";
import "../../styles/Admin.css";

const settings = ["Logout"];
// const MenuLinks = [
//   {
//     title: "Students",
//     link: "/studentTable",
//     table: "Students Table",
//   },
//   {
//     title: "Instructors",
//     link: "/InstructorTable",
//     table: "Instructors Table",
//   },
// ];
const ActiveLinkTitle = () => {
  const location = useLocation();
  // const activeMenuItem = MenuLinks.find(
  //   (item) => item.link === location.pathname
  // );

  return (
    <Box sx={{ position: "absolute" }}>
      {/* {activeMenuItem && (
        <div
          style={{
            paddingLeft: "10px",
            paddingTop: "8px",
            fontSize: "28px",
            fontWeight: "bold",
          }}
        >
          {" "}
          {activeMenuItem.table}
        </div>
      )} */}
    </Box>
  );
};

const UserNavbar = () => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [userInfo, setUserInfo] = React.useState("Admin");

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    window.location.reload();
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{ bgcolor: "white", boxShadow: "none", marginBottom: "5px" }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                mt: 1,
                display: { xs: "none", md: "flex" },
              }}
            >
              {/* <img alt="logo" src={Logo}></img> */}
            </Typography>

            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                mt: 1,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
              }}
            >
              {/* <img alt="logo" src={Logo} style={{ width: "50%" }}></img> */}
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                justifyContent: "center",
                display: { xs: "none", md: "flex" },
              }}
            ></Box>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton sx={{ p: 0 }}>
                  <Avatar alt={userInfo} src="/" />
                  {userInfo}
                  <ExpandMoreIcon
                    sx={{ color: "black" }}
                    onClick={handleOpenUserMenu}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem
                    key={setting}
                    onClick={() => {
                      handleCloseUserMenu();
                      handleLogout();
                    }}
                  >
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Grid Container spacing={2}>
        <Grid item xs={2}>
          <ActiveLinkTitle />
        </Grid>
        {/* <Grid item xs={10}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "16px",
            }}
          >
            {MenuLinks.map((menuItem, index) => (
              <Button
                key={index}
                variant="contained"
                color="primary"
                component={NavLink}
                to={menuItem.link}
                activeClassName="activeLink"
                sx={{ marginRight: "5px" }}
              >
                {menuItem.title}
              </Button>
            ))}
          </Box>
        </Grid> */}
      </Grid>
    </>
  );
};
export default UserNavbar;
